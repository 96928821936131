<template lang="pug">
.main
  .loader
    .bar(v-if="$store.getters.loading")
  .container-xxl
      .row.no-gutters
          .col-12
              .mt-2.mb-5.main-view
                  
                  .mb-5
                      .row
                          .offset-md-3.col-md-6
                              .text-muted.mt-2.small Search: "{{$route.query.q}}" {{total_rows}} hits
                                  b-button-close(@click="reset()")
                              
                              .mt-3(v-if="companies == null || companies.length != 0")
                                  .row
                                    .col-6
                                      label Companies
                                    .col-6
                                      .float-right
                                        b-btn(variant="link", size="sm", :to="'/ecosystem/list?source=qs&keyword='+this.$route.query.q").small Search companies
                        

                                  .spinner(v-if="!companies") Loading
                                  div(v-if="companies")
                                    b-list-group
                                      b-list-group-item(v-if="companies" v-for="entry in companies")
                                          
                                          .media
                                              router-link(:to="'/ecosystem/orgs/'+entry._id+'?source=qs'")

                                                  Logo(:web_entity="entry.web_entity", :name="entry.name" :size="20").mr-2
                                              .media-body(style="max-width: 800px")
                                                  router-link(:to="'/ecosystem/orgs/'+entry._id+'?source=qs'")
                                                      .p-0.m-0 {{entry.name}}
                                                        .small.text-muted.d-inline-block.ml-2 {{entry.web_entity}}, {{entry.city}}
                              

                              .mt-3(v-if="contacts == null || contacts.length != 0")
                                  label Contacts
                                  .spinner(v-if="!contacts") Loading
                                  div(v-if="contacts")
                                    i.small.text-muted(v-if="contacts.length == 0") No contacts found
                                    b-list-group.mt-1
                                      b-list-group-item(v-if="contacts" v-for="entry in contacts")
                                          
                                          .media.mt-1
                                              router-link(:to="'/ecosystem/orgs/'+entry.organization_id+'?source=qs'")
                                                  Logo(:webEntity="entry.web_entity", :name="entry.first_name + ' ' + entry.last_name" :size="20").mr-2
                                              .media-body(style="max-width: 800px")
                                                  router-link(:to="'/ecosystem/orgs/'+entry.organization_id+'?source=qs'")
                                                      h6 {{entry.first_name}} {{entry.last_name}} <span v-if="entry.position">{{entry.position}}</span>

                              
                              .mt-3(v-if="projects == null || projects.length != 0")
                                  .row
                                    .col-6
                                      label Projects
                                    .col-6
                                      .float-right
                                        b-btn(variant="link", size="sm", :to="'/projects?source=qs&keyword='+this.$route.query.q").small Search projects
                        

                                 
                                  .spinner(v-if="!projects") Loading
                                  div(v-if="projects")
                                    i.small.text-muted(v-if="projects.length == 0") No projects found
                                    b-list-group.mt-1
                                      b-list-group-item(v-if="projects" v-for="entry in projects")
                                          
                                          .media.mt-1
                                              router-link(:to="'projects/'+entry._id+'?source=qs'")
                                                  Logo(:webEntity="entry.web_entity", :name="entry.name" :size="20").mr-2
                                              .media-body(style="max-width: 800px")
                                                  router-link(:to="'projects/'+entry._id+''+'?source=qs'")
                                                      h6 {{entry.name}}
                              
                              .mt-3(v-if="topics == null || topics.length != 0")
                                  .row
                                    .col-6
                                      label {{$store.state.instance_config.section_topics_name}}
                                    .col-6
                                      //.float-right
                                        b-btn(variant="link", size="sm", :to="'/topics/usecases/list?source=qs&keyword='+this.$route.query.q").small Search {{$store.state.instance_config.section_topics_name}} 
                                  .spinner(v-if="!projects") Loading
                                  div(v-if="topics")
                                    i.small.text-muted(v-if="topics.length == 0") No use cases found
                                    b-list-group.mt-1
                                      b-list-group-item(v-if="topics" v-for="entry in topics")
                                          
                                          .media.mt-1
                                              router-link(:to="'topics/details/'+entry._id+'?source=qs'")
                                                  Logo(:webEntity="entry.web_entity", :name="entry.name" :size="20").mr-2
                                              .media-body(style="max-width: 800px")
                                                  router-link(:to="'topics/details/'+entry._id+'?source=qs'")
                                                      h6 {{entry.name}}


                        
                                        
                                    
               

</template>

<script>
// @ is an alias to /src

import Vue from 'vue'
import Config from '@/config'
import debounce from 'lodash/debounce';

import ProjectCard from '@/components/ProjectCard.vue'
import Logo from '@/components/Logo.vue'

export default {
  
  name: 'QuickSearch',
  
  data: function () {
    return {
      news: null,
      companies: null,
      projects: null,
      topics: null,
      external: null,
      contacts: null,
      total_rows: 0
    }
  },

  watch: {
    
    '$route.query.q': function () {
      this.updateSearchDebounced();
      
    }
  },

  mounted: function() {
    this.host = Config.config.base_host;
    this.updateSearchDebounced = debounce(this.updateSearch, 100);
    this.updateSearchDebounced();
  },


  computed: {

       
  },

  methods: {

    updateSearch() {
      //this.getNews();
      this.getCompanies();
      //this.getContacts();
    },

    reset() {
      console.log(this.$route.query.q);
      
      this.$router.replace({path: this.$route.query.lastRoute});
    },
    
    getNews() { 
      this.news = null;
      Vue.http.get(`${Config.config.base_host}/news?q=${this.$route.query.q}`).then(res => {
        this.news = res.body.slice(0, 5);
        this.$store.dispatch("stop_loading");
      });
    },

    getCompanies() { 
     
      this.$store.dispatch("start_loading");
      this.companies = null;
      this.projects = null;
      this.external = null;
      this.contacts = null;
      this.topics = null;

      Vue.http.get(`${Config.config.base_host}/organizations/search/autocomplete?q=${this.$route.query.q}`).then(res => {
        
        this.companies = [];
        this.projects = [];
        this.external = [];
        this.contacts = [];
        this.topics = [];

        this.total_rows = res.body.total_rows;
          
        res.body.rows.forEach((it) => {
          
          if(it.doc_type == "Organization") {
            this.companies.push(it);
          }

          if(it.doc_type == "Topic") {
            this.topics.push(it);
          }
        
          if(it.doc_type == "Project") {
            this.projects.push(it);
          }
          if(it.doc_type == "Contact") {
            this.contacts.push(it);
          }
          
        });

        this.$store.dispatch("stop_loading");

        
      });
    },


     getProjects() { 
  
    },



  },
  components: {
     ProjectCard,
     Logo
  }
}

</script>
<style lang="scss" scoped>
@import '@/_vars.scss';
.submenu {
    height: 100%;
    min-height: 100%;
}
.feed-image-small {
  width: 30px;
  height: 30px;
}
</style>